<template>
  <section class="appointment-specialty">
    <div class="appointment-specialty__tabs">
      <div
        v-for="modality in partner.modalities"
        :key="`appointment-modality-${modality.value}`"
        :class="{ 'appointment-specialty__option--selected': chosenModality === modality.value }"
        class="appointment-specialty__option"
        @click="chosenModality = modality.value"
      >
        {{ modality.title }}
      </div>
    </div>

    <div
      :class="[wrapperClass]"
      class="appointment-specialty__box"
    >
      <h2 class="appointment-specialty__title">
        Selecione uma especialidade para começar
      </h2>

      <div class="appointment-specialty__box-wrapper">
        <select-list
          v-model="chosenSpecialty"
          :disabled="ui.isLoading"
          :options.sync="filteredMedicalSpecialties"
          placeholder="Digite o nome da especialidade"
        />
      </div>
    </div>

    <transition
      name="fade"
      mode="in-out"
    >
      <div
        v-if="chosenModality === 'presential'"
        class="appointment-specialty__box"
      >
        <h2 class="appointment-specialty__title">
          informe seu endereço ou cep
        </h2>

        <div class="appointment-specialty__box-wrapper">
          <div class="select-list">
            <input
              v-model="localAddress"
              type="text"
              placeholder="Endereço ou CEP"
              class="select-list__input select-list__input--center appointment-specialty__input"
            >

            <transition
              name="fade"
              mode="in-out"
              v-if="
                autocompleteAddress.length > 0
                  && !autocompleteAddress.some((item) => item.address === localAddress)
              "
            >
              <ul class="select-list__list appointment-specialty__autocomplete">
                <li
                  v-for="(prediction, index) in autocompleteAddress"
                  :key="`address-${index}`"
                  class="select-list__option"
                  @click="changeAddress(prediction)"
                >
                  {{ prediction.address }}
                </li>
              </ul>
            </transition>
          </div>

          <ui-button
            :icon="require('@/assets/img/icons/ico-gps.svg')"
            color="secondary"
            label="Usar minha localização atual"
            class="appointment-specialty__gps"
            @click="getGeolocation"
          />
        </div>
      </div>
    </transition>

    <!-- Bloco escondido: especialidades mais procuradas -->
    <div
      v-if="false"
      class="appointment-specialty__list"
    >
      <h3 class="appointment-specialty__subtitle">
        Especialidades mais procuradas
      </h3>

      <list-filter
        v-model="selectedOption"
        :options="options"
      />
    </div>
    <!-- /Bloco escondido: especialidades mais procuradas -->

    <div class="appointment-specialty__call-to-action">
      <ui-button
        :disabled="ui.isLoading || !isValid"
        :icon="require('@/assets/img/icons/ico-check-disponibilidade.svg')"
        color="primary"
        label="Consultar disponibilidade"
        @click="completeStep(1, chosenSpecialty)"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mask } from 'ke-the-mask';
import { debounce } from 'vue-debounce';
import { Loader } from '@googlemaps/js-api-loader';
import address from '@/api/appointmentScheduling/address';
import medicalSpecialties from '@/mixins/medicalSpecialties';
import completeStep from '@/mixins/completeStep';
import handleError from '@/mixins/handleError';
import SelectList from '@/components/ui/SelectList.vue';
import ListFilter from '@/components/ui/ListFilter.vue';
import UiButton from '@/components/ui/Button.vue';

export default {
  name: 'AppointmentSchedulingSpecialty',

  directives: {
    mask,
  },

  mixins: [medicalSpecialties, completeStep, handleError],

  components: {
    SelectList,
    ListFilter,
    UiButton,
  },

  data() {
    return {
      chosenModality: null,
      chosenSpecialty: null,
      autocompleteAddress: [],
      localAddress: null,
      latitude: null,
      longitude: null,
      // filter list:
      selectedOption: null,
      options: [],
      service: null,
    };
  },

  computed: {
    ...mapGetters('partner', ['partner', 'medicalSpecialties', 'paymentMethods']),
    ...mapGetters('scheduling', ['appointment', 'patient', 'address']),

    appointmentSpecialty() {
      return this.appointment.specialty;
    },

    isValid() {
      if (this.chosenModality === 'telemedicine') {
        return !!this.chosenSpecialty;
      }

      return !!(!!this.chosenSpecialty && this.address.latitude && this.address.longitude);
    },

    filteredMedicalSpecialties() {
      return this.medicalSpecialties
        .filter((specialty) => specialty.modality === this.appointment.modality);
    },

    wrapperClass() {
      if (this.partner.modalities.length === 2) {
        return this.chosenModality === this.partner.modalities[0].value
          ? 'appointment-specialty__box--no-border-r'
          : 'appointment-specialty__box--no-border-l';
      }

      return 'appointment-specialty__box--no-border-r';
    },
  },

  watch: {
    'appointment.modality': {
      deep: false,
      immediate: false,
      handler(modality) {
        if (modality && this.chosenSpecialty) {
          const chosenSpecialty = this.medicalSpecialties
            .find((specialty) => {
              const { id } = this.chosenSpecialty;
              return specialty.id === id && specialty.modality === modality;
            });

          this.chosenSpecialty = { ...chosenSpecialty };
        }
      },
    },

    appointmentSpecialty: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.chosenSpecialty = value;
        }
      },
    },

    chosenModality: {
      deep: false,
      immediate: false,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.setAppointment({ modality: newValue });
        }
      },
    },

    localAddress: {
      deep: false,
      immediate: false,
      handler(newValue, oldValue) {
        if (
          newValue
          && newValue !== oldValue
          && !this.autocompleteAddress.some((item) => item.address === this.localAddress)
        ) {
          this.localAddress = this.maskCep(this.localAddress);
          this.searchAddress();
        }

        if (newValue === '' || newValue === null) {
          this.autocompleteAddress = [];
          this.localAddress = null;
          this.latitude = null;
          this.longitude = null;
          this.setAddress({ latitude: this.latitude, longitude: this.longitude });
        }
      },
    },
  },

  created() {
    if (document.domain === 'paciente.drconecta.com.br') {
      if (Object.prototype.hasOwnProperty.call(window, 'fbq')) {
        window.fbq('track', 'PatientViewSpecialty');
      }
    }

    if (this.medicalSpecialties.length === 0) {
      this.fetchMedicalSpecialties()
        .then(() => this.checkQueryParams());
    } else {
      this.checkQueryParams();
    }

    this.chosenModality = this.appointment.modality || this.partner.modalities[0].value;
    this.chosenSpecialty = this.appointment.specialty;
    this.localAddress = this.address.search;

    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS,
      libraries: ['places'],
      language: 'pt-BR',
      region: 'BR',
    });

    loader.load()
      .then(() => {
        // eslint-disable-next-line no-undef
        this.service = new google.maps.places.AutocompleteService();
      })
      .catch(console.log);
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar']),
    ...mapActions('scheduling', ['setAppointment', 'setAddress']),

    getGeolocation() {
      const { geolocation } = navigator;

      geolocation.getCurrentPosition((data) => {
        const localAddress = address;
        const { latitude, longitude } = data.coords;

        this.toggleProgressBar();

        localAddress({ latitude, longitude })
          .getMapAddress()
          .then((address) => {
            this.autocompleteAddress = [address];
          })
          .finally(this.toggleProgressBar(false));
      });
    },

    getCoordsFromAddress: debounce(function () {
      const localAddress = address;

      this.toggleProgressBar();

      localAddress({ address: this.localAddress })
        .getMapAddress()
        .then((address) => {
          this.autocompleteAddress = [address];
        })
        .finally(this.toggleProgressBar(false));
    }, 1000),

    changeAddress(address) {
      if (address) {
        this.localAddress = address.address;
        this.latitude = address.latitude;
        this.longitude = address.longitude;
        this.setAddress({
          latitude: this.latitude,
          longitude: this.longitude,
          search: this.localAddress,
        });
        this.autocompleteAddress = [];
      }
    },

    searchAddress: debounce(function () {
      if (this.localAddress !== null && this.localAddress !== '') {
        this.service.getQueryPredictions({
          input: this.localAddress,
          types: ['(address)'],
          region: 'BR',
          componentRestrictions: { country: 'br' },
        }, this.showSuggestions);
      }
    }, 1000),

    showSuggestions(predictions) {
      this.autocompleteAddress = [];

      predictions.forEach((prediction) => {
        const { 'description': address, 'place_id': placeId } = prediction;

        // eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ placeId }, (results, status) => {
          // eslint-disable-next-line no-undef
          if (status === google.maps.GeocoderStatus.OK) {
            const latitude = results[0].geometry.location.lat();
            const longitude = results[0].geometry.location.lng();

            this.autocompleteAddress.push({
              address,
              latitude,
              longitude,
            });
          }
        });
      });
    },

    checkQueryParams() {
      const { t, ms } = this.$route.query;

      if (t && ms) {
        const modality = parseInt(t, 10) === 1 ? 'telemedicine' : 'presential';
        const medicalSpecialty = this.medicalSpecialties
          .find((s) => s.modality === modality && s.id === parseInt(ms, 10));

        this.$nextTick(() => {
          this.chosenModality = modality;
          this.chosenSpecialty = medicalSpecialty;

          if (modality === 'telemedicine') {
            this.completeStep(1, this.chosenSpecialty);
          }
        });
      }
    },

    maskCep(address = null) {
      const matches = String(address).match(/(\d{8})/g) || [];

      if (address && matches.length > 0) {
        let newAddress = address;

        const codes = matches.map((item) => {
          const masked = item.replace(/^([\d]{5})([\d]{3})?/g, '$1-$2');

          return { entry: item, masked };
        });

        codes.forEach((code) => {
          newAddress = newAddress.replace(code.entry, code.masked);
        });

        return newAddress;
      }

      return address;
    },
  },
};
</script>
